import WelcomeCanvas from './components/WelcomeCanvas/WelcomeCanvas';

function App() {
  return (
    <>
      <WelcomeCanvas />
    </>
  );
}

export default App;
